import React from "react";

import {KatButton, KatModal, KatSpinner} from "@amzn/katal-react";
import Signature, {PointGroup} from "signature_pad";

import {createContract, generateContractFileWithoutSign} from "../services/ContractApiProxy";
import {trackingEvent} from "../services/MetricsApiProxy";

export interface ContractPreviewPageProps {
    data: string | null;
}

export interface ContractPreviewPageState {
    contractPicList: string[];
    callbackUrl: string | null;
    signaturePad: Signature | null;
    signaturePadToggled: boolean;
    signatureSigned: boolean;
    signatureRedoArray: PointGroup[];

    openId: string | null;
}

export class ContractPreviewPageWidget extends React.Component<ContractPreviewPageProps, ContractPreviewPageState> {

    constructor(props: ContractPreviewPageProps) {
        super(props);
        this.readySignaturePad = this.readySignaturePad.bind(this);
        this.toggleSignaturePad = this.toggleSignaturePad.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.generateContractFileWithoutSign = this.generateContractFileWithoutSign.bind(this);
        this.createContract = this.createContract.bind(this);
        this.state = {
            contractPicList: [],
            callbackUrl: null,
            signaturePad: null,
            signaturePadToggled: false,
            signatureSigned: false,
            signatureRedoArray: [],
            openId: null,
        };

        window.onload = function() {
            const canvas: HTMLCanvasElement | null = document.querySelector("#signature-pad-canvas");

            if (canvas) {
                // Update its width and height attributes to match its actual dimensions.
                canvas.width = canvas.clientWidth;
                canvas.height = canvas.clientHeight;
            }
        };
    }

    componentDidMount(): void {
        if (!this.state.signatureSigned) {
            this.readySignaturePad();
            this.generateContractFileWithoutSign();
        }
    }

    private readySignaturePad(): void {
        const canvas: HTMLCanvasElement | null = document.querySelector("#signature-pad-canvas");
        if (canvas) {
            this.setState({
                signaturePad: new Signature(canvas, {
                    backgroundColor: "rgb(255, 255, 255)"
                })
            });
        }
    }

    private toggleSignaturePad(): void {
        this.setState({
            signaturePadToggled: !this.state.signaturePadToggled
        })
        this.handleClear();
    }

    private handleSubmit(): void {
        if (this.state.signaturePad && !this.state.signaturePad.isEmpty()) {
            const signature: string = this.state.signaturePad.toDataURL("image/jpeg");
            // 4.签署合同页，签名弹窗，提交
            trackingEvent('个人签名弹窗', 'click', '提交', this.state.openId);
            this.createContract(signature);
            this.toggleSignaturePad();
        }
    }

    private handleClear(): void {
        this.state.signaturePad?.clear();
        this.setState({
            signatureRedoArray: []
        });
    };

    private handleConfirm(): void {
        // 7.签署合同页，确定提交
        trackingEvent('个人签名弹窗', 'click', '确认提交', this.state.openId);
        if (this.state.callbackUrl) {
            // We don't want the user to go back to the contract preview page.
            window.location.replace(this.state.callbackUrl);
        }
    }

    private async generateContractFileWithoutSign(): Promise<void> {
        if (this.props.data) {
            try {
                const response: Response = await generateContractFileWithoutSign(this.props.data);
                if (response.ok) {
                    const responseBody = await response.json();
                    this.setState({
                        contractPicList: responseBody.data.image as string[],
                        openId: responseBody.data.openid as string,
                    });
                    // 1.签署合同页，进入
                    trackingEvent('签署合同页', 'view', '进入页面', this.state.openId);
                    window.addEventListener('beforeunload', ()=>{
                        // 2.签署合同页，离开
                        trackingEvent('签署合同页', 'close', '离开页面', this.state.openId);
                    });
                }
            } catch (e) {
                console.error("Exception occurred in generateContractFileWithoutSign.", e);
            }
        }
    }

    private async createContract(signature: string): Promise<void> {
        this.setState({
            contractPicList: [],
            signatureSigned: true
        });
        if (this.props.data) {
            try {
                const response: Response = await createContract(this.props.data, signature);
                if (response.ok) {
                    const responseBody = await response.json();
                    this.setState({
                        contractPicList: responseBody.data.image as string[],
                        callbackUrl: responseBody.data.callback_url as string
                    });
                }
            } catch (e) {
                console.error("Exception occurred in createContract.", e);
            }
        }
    }

    render() {
        // The key prop specified for components/elements inside pictures is to help React identify
        // which items have changed. See https://reactjs.org/docs/lists-and-keys.html
        let pictures: JSX.Element[] = [];
        this.state.contractPicList.forEach((contractPic, index) => {
            pictures.push(
                <img key={"pic_" + index} src={contractPic}></img>
            )
        });
        // Display a spinner instead if the API call to fetch the preview pictures hasn't finished.
        if (pictures.length === 0) {
            pictures = [<KatSpinner key="spinner" variant="default" size="large"> </KatSpinner>];
        }

        if (this.state.signatureSigned) {
            document.title = "确认合同";
            return (
                <div className="contract-preview-container">
                    <div className="contract-picture-container">
                        {pictures}
                    </div>
                    <div className="container">
                        <KatButton id="confirmation-button" className="katButton" variant="primary"
                                   onClick={() => this.handleConfirm()}>
                            确认
                        </KatButton>
                    </div>

                </div>
            );
        } else {
            document.title = "签署合同";
            return (
                <div className="contract-preview-container">
                    <div className="contract-picture-container">
                        {pictures}
                    </div>

                    <div className="container">
                        <KatButton id="signature-button" className="katButton" variant="primary"
                                   onClick={() => {
                                       this.toggleSignaturePad();
                                       // 3.签署合同页，点击签名按钮
                                       trackingEvent('签署合同', 'click', '签名', this.state.openId);
                                   }}>
                            签名
                        </KatButton>
                    </div>

                    <KatModal visible={this.state.signaturePadToggled}
                              onClose={() => {
                                  this.toggleSignaturePad();
                                  // 6.签署合同页，签名弹窗，关闭
                                  trackingEvent('个人签名弹窗', 'click', '关闭', this.state.openId);
                              }}>
                        <span slot="title">个人签名</span>

                        <div className="canvas-container">
                            <canvas id="signature-pad-canvas" className="signature-pad-canvas"></canvas>
                        </div>

                        <div className="kat-group-horizontal container" slot="footer">
                            <KatButton id="clear-button" className="katButton" onClick={() => {
                                this.handleClear(); // 5.签署合同页，签名弹窗，清除
                                trackingEvent('个人签名弹窗', 'click', '清除', this.state.openId);}
                            } variant="secondary">
                                清除
                            </KatButton>
                            <KatButton id="submit-button" className="katButton" onClick={() => this.handleSubmit()}
                                       variant="primary">
                                提交
                            </KatButton>
                        </div>
                    </KatModal>
                </div>
            );
        }
    }
}
