import React from 'react';

import "../styles/contract-preview-page.scss";
import {ContractPreviewPageWidget} from './ContractPreviewPage';

export class App extends React.Component {
    render() {
        const queryParams = new URLSearchParams(window.location.search);
        return (
            <div className="wrapper">
                <div slot="header">
                    <div className="page-header"></div>
                </div>
                <ContractPreviewPageWidget data={queryParams.get("data")}/>
            </div>
        );
    }
}

export default App;
