/* istanbul ignore file */
import KatalLogger, { Level } from "@amzn/katal-logger";

const katalLoggerConfig = {
  url: "", // Once KatalLogger use case emerges, {{KatalLoggerUrl}} should be used.
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: false,
};

export const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();
