const GAMMA_HOST: string = "https://dev.ma.globalsellingcommunity.cn";
const PROD_HOST: string = "https://ma.globalsellingcommunity.cn";

// gamma will get replaced by Katal.
// See: https://katal.amazon.dev/for-developers/build-and-deploy/manifest-templating/
// See: https://sage.amazon.com/posts/1216615 why it's using String constructor
const stage: string = String("gamma");
const endpoint: string = stage === "prod" ? PROD_HOST : GAMMA_HOST;


/**
 * metrics api proxy
 * @param page
 * @param action
 * @param action_name
 * @param openid
 */
export function trackingEvent(
    page: string,
    action: string,
    action_name: string,
    openid: string | null) {

    const event = new FormData();
    event.append('page', page);
    event.append('page_url', location.href);
    event.append('action', action);
    event.append('action_name', action_name);
    if (openid) event.append('openid', openid);
    try {

        const beaconUrl = `${endpoint}/app/amazon/BurialPoint/BurialPoint`
        navigator.sendBeacon(beaconUrl, event);
    } catch (error) {
        console.error('埋点错误：', error);
    }
}